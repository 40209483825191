<template>
  <section>
    <article class="sidebar scroll-content">
      <h5>{{$t('Categories')}}</h5>
      <ul class="blockchain">
        <li
          v-for="(item, i) in category"
          :key="i"
          @click="switchCategory(item)"
          :class="{
            active:
              selectedCateID == item.ID &&
              (selectedCateID==0?selectedTagID == item.tag:true )
          }"
        >
          {{ item.title }} <span>{{ item.count }}</span>
        </li>
      </ul>
    </article>
    <article class="dapp">
      <!-- tag导航 -->
      <header ref="header">
        <div class="search-box">
          <input
            type="text"
            class="search"
            placeholder="Search"
            v-model="searchInfo"
            @input="debouncedSearchProject"
          />
          <img
            src="@/assets/img/icon/close.png"
            width="16"
            class="close-btn"
            v-show="searchInfo"
            @click="emptySearch"
          />
        </div>
        <ul class="labels" v-if="tags.length > 0">
          <li
            v-for="tag in tags"
            :key="tag.ID"
            :class="{ active: selectedTagID == tag.ID }"
            @click="switchTags(tag)"
          >
            {{ tag.title }}
          </li>
        </ul>
      </header>
      <!-- 项目列表 -->
      <div class="main scroll-content" ref="projectList" @scroll="getMore">
        <ul class="dapp-list" ref="dappList" v-if="projectList.length > 0">
          <li
            class="dapp-info clearfix"
            v-for="project in projectList"
            :key="project.ID"
            @click="goDetail(project)"
          >
            <img
              v-if="project.img"
              :src="
                project_oss_link +
                project.img
              "
            />
            <img v-else src="@/assets/img/icon/default.png" />
            <div class="dapp-text">
              <h4 class="dapp-title">
                <span>{{ project.title }}</span>
                <img
                  v-if="project.isNew"
                  src="@/assets/img/icon/new.png"
                  height="16"
                />
              </h4>
              <!-- 邮箱等链接 -->
              <p class="contact">
                <img
                  v-for="(item, i) in getProjectContact(project)"
                  :key="i"
                  :src="require('@/assets/img/tag/' + item + '.png')"
                  @click.stop="openLink(project[item], item)"
                />
              </p>
              <p class="dapp-slogan">{{ project.introduction }}</p>
              <div class="dapp-type">
                <label>{{ cateTitleByID(project) }}</label>
                <template v-if="project.tags.length > 0">
                  <label v-for="(item, index) in project.tags" :key="index"
                    >{{ item }}
                  </label>
                </template>
              </div>
            </div>
          </li>
        </ul>
        <!-- 无结果 -->
        <div v-else-if="searchInfo" class="noSearchResult">
          <p>{{$t('NoResultsFound').replace('w%','"'+searchInfo+'"')}}</p>
          <br />
          <p v-html="$t('NoResultsInfo')"></p>
        </div>
        <div class="load-all" v-if="isAllList"><span>{{$t('NoMore')}}</span></div>
      </div>
      <!-- go top -->
      <img
        class="goTop"
        src="@/assets/img/icon/goTop.png"
        width="40"
        v-if="isBack"
        @click="goTop"
      />
    </article>
  </section>
</template>
<script>
export default {
  props: ["cateID", "tagID"],
  data() {
    return {
      isAllList: false, //是否获得全部数据
      isBack: false, //是否显示返回顶部按钮
      projectList: [], //项目列表
      tags: [], //项目标签
      page: 0,
      searchInfo: "",
      limit_num: window.innerWidth <= 768 ? 10 : 71,
      sw: false,
      category: "",
      selectedCateID: 0,
      selectedTagID: 0,
    };
  },
  computed: {
    tagList() {
      let list = this.tags.map((v) => v.ID);
      return list;
    },
  },
  created() {
    this.getCategory();
    //`_.debounce` 是一个通过 Lodash 限制操作频率的函数。
    this.debouncedSearchProject = this.lodash.debounce(this.searchProject, 500);
    this.debouncedsetDomLimit = this.lodash.debounce(this.setDomLimit, 500);
  },
  mounted() {
    this.setDomLimit();
    window.onresize = () => {
      this.debouncedsetDomLimit();
    };
    window.onscroll=()=>{
      this.setHeight();
    }
  },
  methods: {
    cateTitleByID(project) {
      if (this.category&&this.category.length > 1) {
        let category = this.category.filter((v) => v.ID == project.cateID)[0];
        return category ? category.title : null;
      }
      return null;
    },
    //切换链
    switchCategory(item) {
      this.selectedCateID = item.ID;
      this.$router.push("/projects?cateID=" + item.ID + "&tagID=" + item.tag);
    },
    getCategory() {
      this.$loading(1);
      let category = [
        {
          ID: 0,
          title: "All",
          count: "",
          tag: 0,
        },
      ];
      this.axios
        .get(this.domain + "getCategory")
        .then((res) => {
          if (res.data.success) {
            let list = res.data.data.category;
            category[0].count = list.reduce((sum, v) => {
              if (v.ID == 0) {
                v.tag = 12;
              } else {
                v.tag = 0;
                sum += v.count;
              }

              return sum;
            }, 0);

            category = category.concat(list);
          }
          this.category = category;
          this.getListByCateTag();
        })
        .catch((err) => {
          console.log(err);
          this.$loading(0);
        });
    },
    getProjectContact(project) {
      let c = this.contact.filter((v) => project[v]);
      return c;
    },
    goDetail(project) {
      if (!project) {
        return false;
      }
      // let routeUrl = this.$router.resolve({path:"/project/" + project.ID})
      // console.log(routeUrl);
      window.open("/#/project/" + project.ID,'_blank');
    },
    setHeight(){
      if(!this.$refs.projectList||!this.$refs.header){
        return false;
      }
      if (window.innerWidth <= 768) {
        //设置项目内容高度
        this.$refs.projectList.style.height = window.innerHeight - 300 + "px";
      }else{
        this.$refs.projectList.style.height =
          window.innerHeight - this.$refs.header.offsetHeight - 168 + "px";
      }
    },
    setDomLimit() {
      if (window.innerWidth < 1264) {
        //设置每次请求数量
        this.limit_num = 10;
      } else {
        this.limit_num = 71;
      }
      this.setHeight();
    },
    switchTags(tag) {
      this.$router.push(
        "/projects?cateID=" + this.selectedCateID + "&tagID=" + tag.ID
      );
    },
    // 通过路由category变化请求数据
    getListByCateTag() {
      this.selectedCateID = this.cateID?this.cateID:0;
      this.getTags();
    },
    getListByTag() {
      this.selectedTagID = this.tagID&&this.tagList.includes(this.tagID)||this.tagID==12 ? this.tagID : 0;
      if(this.selectedCateID==0&&this.selectedTagID!=12){
        this.category[0].tag=this.selectedTagID;
      }
      this.getList();
    },
    getTags() {
      this.$loading(1);
      //获取标签列表
      var URL =
        this.domain +
        "getTags" +
        (this.selectedCateID!=0 ? "?cateID=" + this.selectedCateID : "");

      this.axios
        .get(URL)
        .then((res) => {
          if (res.data.success) {
            this.tags = [
              {
                ID: "0",
                title: "All",
              },
            ].concat(res.data.data.tags);
          } else {
            this.tags = [
              {
                ID: "0",
                title: "All",
              },
            ];
          }
          this.getListByTag();
          this.setHeight();
        })
        .catch((err) => {
          this.tags = [
            {
              ID: "0",
              title: "All",
            },
          ];
          this.getListByTag();
          console.log(err);
        });
    },
    getList() {
      //获取项目列表
      var URL = "";
      this.$loading(1);
      this.page = 0;
      if (this.$refs.projectList) {
        this.$refs.projectList.scrollTo(0, 0);
      }
      this.searchInfo = this.searchInfo.trim();
      if (this.searchInfo) {
        URL =
          this.domain +
          "searchBsc?flag=1&key=" +
          this.searchInfo +
          "&tagID=" +
          this.selectedTagID;
      } else {
        URL =
          this.domain +
          "getBscList?limit=" +
          this.limit_num +
          "&tagID=" +
          this.selectedTagID +
          "&page=0" +
          (this.selectedCateID
            ? "&cateID=" + this.selectedCateID
            : "") +
          "&flag=" +
          (this.selectedCateID!=0 ? 1 : this.selectedTagID == 12 ? 1 : 0);
      }

      // 首次请求数据
      this.axios.get(URL).then((res) => {
        this.$loading(0);
        if (res.data.success) {
          this.projectList = res.data.data;

          // 搜索框有数据
          if (this.searchInfo) {
            // 将开关关闭
            this.sw = false;
            if (this.projectList && this.projectList.length > 0) {
              this.isAllList = true;
            } else {
              this.isAllList = false;
            }
          } else {
            if (this.projectList.length < this.limit_num) {
              this.isAllList = true;
              this.sw = false;
            } else {
              this.isAllList = false;
              this.sw = true;
            }
          }
        }
      });
    },
    searchProject() {
      this.$loading(1);
      //搜索项目
      this.$refs.projectList.scrollTo(0, 0);
      this.searchInfo = this.searchInfo.trim();
      if (!this.searchInfo) {
        this.getList();
        return false;
      }
      this.sw = false;
      var URL =
        this.domain +
        "searchBsc?key=" +
        this.searchInfo +
        "&tagID=" +
        this.selectedTagID;

      this.axios.get(URL).then((res) => {
        this.$loading(0);
        if (res.data.success) {
          this.projectList = res.data.data;
          if (this.projectList && this.projectList.length > 0) {
            this.isAllList = true;
          } else {
            this.isAllList = false;
          }
        }
      });
    },
    emptySearch() {
      //清空搜索框
      this.searchInfo = "";
      this.getList();
    },
    getMore() {
      // 返回顶部按钮
      if (
        this.$refs.projectList.scrollTop > this.$refs.projectList.offsetHeight
      ) {
        this.isBack = true;
      } else {
        this.isBack = false;
      }

      // 滚动加载更多
      if (!this.searchInfo.trim()) {
        // 判断是否打开开关
        if (this.sw == true) {
          // 判断是否滚动到底部
          if (
            this.$refs.dappList.offsetHeight -
              this.$refs.projectList.scrollTop -
              50 <=
            this.$refs.projectList.offsetHeight
          ) {
            this.page++;
            this.$loading(1);
            // 将开关关闭
            this.sw = false;

            var URL =
              this.domain +
              "getBscList?limit=" +
              this.limit_num +
              "&tagID=" +
              this.selectedTagID +
              "&page=" +
              this.page +
              (this.selectedCateID
                ? "&cateID=" + this.selectedCateID
                : "") +
              "&flag=" +
              (this.selectedCateID!=0 ? 1 : this.selectedTagID == 12 ? 1 : 0);

            this.axios.get(URL).then((res) => {
              if (res.data.success) {
                this.$loading(0);
                if (res.data.data.length > 0) {
                  this.projectList = this.projectList.concat(res.data.data);
                }
                // 数据更新完毕，将开关打开
                if (res.data.data.length < this.limit_num) {
                  this.isAllList = true;
                  this.sw = false;
                } else {
                  this.isAllList = false;
                  this.sw = true;
                }
              }
            });
          }
        }
      }
    },
    goTop() {
      this.$refs.projectList.scrollTo(0, 0);
    },
  },
  watch: {
    limit_num() {
      this.getList();
    },
    cateID() {
      this.getListByCateTag();
    },
    tagID() {
      this.getListByTag();
    },
  },
};
</script>
<style scoped>
/*滚动条样式*/

.scroll-content::-webkit-scrollbar {
  width: 6px;
}
.scroll-content::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #fafafa;
}
.blockchain li {
  padding: 12px;
  line-height: 24px;
  position: relative;
  cursor: pointer;
}
@media (min-width: 769px){
  section{
    display: flex;
    padding: 24px 24px 0;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .sidebar{
    border-radius: 20px;
    width: 280px;
    max-height: calc(100vh - 180px);
    overflow: auto;
    margin-right: 40px;
    padding-bottom: 24px;
    background: #fafafa;
  }
  .sidebar h5{
    padding: 24px;
    font:bold 14px/16px Rubik-Medium;
  }
  .blockchain li {
    font-size: 18px;
    padding: 12px 24px 12px 48px
  }
  .blockchain li span {
    font-size: 14px;
    margin-left: 4px;
    vertical-align: baseline;
  }
  .blockchain li.active{
    background: #f5f5f5;
    font:bold 18px/24px Rubik-Medium;
  }
  .blockchain li.active:before {
    content: "";
    position: absolute;
    left: 24px;
    top: 20px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #000;
  }
  .dapp{
    flex: 1;
  }
}
@media (max-width: 768px) {
  section{
    display: block;
  }
  .blockchain{
    display: flex;
    overflow-x: auto;
    background: #f5f5f5;
  }
  .sidebar h5,.blockchain li span{
    display: none;
  }
  .blockchain li:first-child{
    padding-left: 16px;
  }
  .blockchain li:last-child{
    padding-right: 16px;
  }
  .blockchain li.active{
    font:bold 14px/24px Rubik-Medium;
  }
  .search-box {
    padding: 12px 16px 0 16px;
  }
  .close-btn {
    right: 24px;
    top: 20px;
  }
}
</style>
